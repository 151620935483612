export default [
    {
        name: 'SupplierList',
        path: 'suppliers',
        component: () => import('./views/SupplierList.vue'),
        meta: {
            title: 'Suppliers',
            perms: 'suppliers',
            icon: 'fa-solid fa-parachute-box',
        },
    },
    {
        name: 'SupplierCreate',
        path: 'suppliers/create',
        component: () => import('./views/SupplierForm.vue'),
    },
    {
        name: 'SupplierUpdate',
        path: 'suppliers/:supplier/update',
        component: () => import('./views/SupplierForm.vue'),
    },
]
