export default [
    {
        name: 'MonthlyCommissionList',
        path: 'commissions/monthly',
        component: () => import('./views/MonthlyCommissionList.vue'),
        meta: {
            title: 'Monthly Commissions',
            perms: 'admin',
            icon: 'fa-solid fa-piggy-bank',
        },
    },
    {
        name: 'MonthlyCommissionDetails',
        path: 'commissions/monthly/:commission',
        component: () => import('./views/MonthlyCommissionDetails.vue'),
    },
]
