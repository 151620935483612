export default [
    {
        name: 'ClientInvoiceList',
        path: 'leads/invoices/clients',
        component: () => import('./views/ClientInvoiceList.vue'),
        meta: {
            title: 'Client Invoices',
            perms: 'advisor',
        },
    },
    {
        name: 'PartnerInvoiceList',
        path: 'leads/invoices/partners',
        component: () => import('./views/PartnerInvoiceList.vue'),
        meta: {
            title: 'Partner Invoices',
            perms: 'advisor',
        },
    },
    {
        name: 'SupplierInvoiceList',
        path: 'leads/invoices/suppliers',
        component: () => import('./views/SupplierInvoiceList.vue'),
        meta: {
            title: 'Supplier Invoices',
            perms: 'advisor',
        },
    },
]
