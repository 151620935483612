export const currencyAmount = function (amount) {
    return Math.round(amount * 100) / 100.0
}

export const currencyExchange = function (amount, rate) {
    return currencyAmount(amount * rate)
}

export const currencyIcon = function (value) {
    switch (value) {
        case 'EUR':
            return 'fa-solid fa-euro-sign'
        case 'USD':
            return 'fa-solid fa-dollar-sign'
        case 'GBP':
            return 'fa-solid fa-pound-sign'
        case 'MXN':
            return 'fa-solid fa-dollar-sign'
        case 'COP':
            return 'fa-solid fa-dollar-sign'
        case 'EGP':
            return 'fa-solid fa-pound-sign'
    }
    return undefined
}

export const formatCurrency = function (value, currency = 'EUR') {
    const strValue = (value || 0.0).toLocaleString('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })

    switch (currency) {
        case 'EUR':
            return `${strValue} €`
        case 'USD':
            return `$ ${strValue}`
        case 'GBP':
            return `£ ${strValue}`
        case 'MXN':
            return `$ ${strValue} MXN`
        case 'AED':
            return `إ.د ${strValue} AED`
        case 'CHF':
            return `${strValue} CHF`
        case 'COP':
            return `$ ${strValue} COP`
        case 'EGP':
            return `£ ${strValue} EGP`
        case 'QAR':
            return `QR ${strValue}`
        case 'MAD':
            return `درهم ${strValue} MAD`
        case 'DKK':
            return `DKK ${strValue} kr`
        case 'SEK':
            return `SEK ${strValue} kr`
        case 'NOK':
            return `NOK ${strValue} kr`
        case 'PLN':
            return `${strValue} zł`
        case 'TRY':
            return `${strValue} TL`
        case 'THB':
            return `฿ ${strValue} THB`
        case 'CZK':
            return `${strValue} Kč`
        case 'CNY':
            return `¥ ${strValue} CNY`
        case 'IDR':
            return `Rp ${strValue} IDR`
        case 'JPY':
            return `¥ ${strValue} JPY`
    }

    return strValue
}
