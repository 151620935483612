import isNil from 'lodash.isnil'

export const percent = function (value) {
    if (isNil(value) || Number.isNaN(value)) {
        return '0.00%'
    }
    return `${value.toFixed(2)}%`
}

export default {
    percent: percent,
    float: function (value, places) {
        return value.toFixed(places)
    },
    date: function (value) {
        return new Date(value * 1000).toTimeString()
    },
    lines: function (value) {
        return value.join('<br>')
    },
}
