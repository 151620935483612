import OperationsRoutes from './modules/operations/routes'

export default [
    {
        name: 'FiscalDocsList',
        path: 'finances/docs',
        component: () => import('./views/FiscalDocsList.vue'),
        meta: {
            title: 'Fiscal Docs',
            perms: 'finances',
            icon: 'fa-solid fa-folder-open',
        },
    },
    {
        name: 'FiscalDocsCreate',
        path: 'finances/docs/create',
        component: () => import('./views/FiscalDocsForm.vue'),
    },
    {
        name: 'FiscalDocsUpdate',
        path: 'finances/docs/:doc',
        component: () => import('./views/FiscalDocsForm.vue'),
    },
    {
        name: 'LeadFinances',
        path: 'finances/leads',
        component: () => import('./views/LeadFinances.vue'),
        meta: {
            title: 'Lead Finances',
            perms: 'finances',
            icon: 'fa-solid fa-chart-bar',
        },
    },
    {
        name: 'ClientBalances',
        path: 'finances/balances/clients',
        component: () => import('./views/ClientBalances.vue'),
        meta: {
            title: 'Client Balances',
            perms: 'finances',
            icon: 'fa-solid fa-scale-balanced',
        },
    },
    {
        name: 'ClientBalancesDetails',
        path: 'finances/balances/clients/:client',
        component: () => import('./views/ClientBalancesDetails.vue'),
    },
].concat(OperationsRoutes)
