export default [
    {
        name: 'EmployeeList',
        path: 'hr/employees',
        component: () => import('./views/EmployeeList.vue'),
        meta: {
            title: 'Employees',
            perms: 'hr',
            icon: 'fa-solid fa-people-arrows',
        },
    },
    {
        name: 'EmployeeCreate',
        path: 'hr/employees/create',
        component: () => import('./views/EmployeeForm.vue'),
    },
    {
        name: 'EmployeePayrollList',
        path: 'hr/employees/payrolls',
        component: () => import('./views/EmployeePayrollList.vue'),
        meta: {
            title: 'Employees Payrolls',
            perms: 'hr',
            icon: 'fa-solid fa-money-check-dollar',
        },
    },
    {
        name: 'EmployeePayrollImport',
        path: 'hr/employees/payrolls/import',
        component: () => import('./views/EmployeePayrollImport.vue'),
        meta: {
            title: 'Import Employees Payrolls',
            perms: 'hr',
            icon: 'fa-solid fa-file-import',
        },
    },
    {
        name: 'EmployeeUpdate',
        path: 'hr/employees/:employee',
        component: () => import('./views/EmployeeForm.vue'),
    },
    {
        name: 'EmployeePayrollUpdate',
        path: 'hr/employees/:employee/payrolls/:payroll',
        component: () => import('./views/EmployeePayrollForm.vue'),
    },
    {
        name: 'EmployeeSystemUsage',
        path: 'hr/employees/:employee/system_usage',
        component: () => import('./views/EmployeeSystemUsage.vue'),
    },
    {
        name: 'EmployeeErrorList',
        path: 'hr/employees/errors',
        component: () => import('./views/EmployeeErrorList.vue'),
        meta: {
            title: 'Employees Errors',
            perms: 'hr',
            icon: 'fa-solid fa-bug',
        },
    },
    {
        name: 'EmployeeErrorCreate',
        path: 'hr/employees/errors/create',
        component: () => import('./views/EmployeeErrorForm.vue'),
    },
    {
        name: 'EmployeeErrorUpdate',
        path: 'hr/employees/errors/:error',
        component: () => import('./views/EmployeeErrorForm.vue'),
    },
]
